.footer_area{
    color: _palette(accent);
    

}

.quote_header{
    color:#2f3133;
    text-align: center;
    font-weight: 900;
    font-size: xx-large;
    padding:32px;
}

.quote_header:hover{
    color: _palette(accent);
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.the_quote{
    color:#2f3133;
    font-size:x-large;
    font-style: italic;
}

.source_image{
    width:100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.quote_wrapper{
display: flex;
justify-content: space-between;
}

.quote_text{
    font-family: georgia, serif;
}

.originator{
    font-weight: bold;
    text-align: center;
    font-size: 20px;
}